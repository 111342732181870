import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { trimData } from '../../utils/helper_functions';
import { providers } from '../../utils/constants';

const UpdateModal = ({ isOpen, onRequestClose, model, onUpdate }) => {
    const data = {
        provider: '',
        name: '',
        temperature: 0,
        max_tokens: 0,
        budget_tokens: 0,
    };

    const [formData, setFormData] = useState(data);
    const [initialData, setInitialData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (model) {
            const initialFormData = {
                provider: model.provider || '',
                name: model.name || '',
                temperature: model.temperature || 0,
                max_tokens: model.max_tokens || 0,
                budget_tokens: model.budget_tokens || 0,
            };
            setFormData(initialFormData);
            setInitialData(initialFormData);
        }
    }, [model]);

    useEffect(() => {
        if (initialData) {
            const trimmedFormData = trimData(formData);
            const trimmedInitialData = trimData(initialData);

            const isFormDataChanged = JSON.stringify(trimmedFormData) !== JSON.stringify(trimmedInitialData);
            setIsSaveDisabled(!isFormDataChanged);
        }
    }, [formData, initialData]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'number' && value === '' ? 0 : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const trimmedFormData = trimData(formData);
        await onUpdate(model.id, trimmedFormData);
        onRequestClose();
        setIsLoading(false);
    };

    const handleClose = () => {
        setFormData(data);
        setInitialData(null);
        onRequestClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleClose}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleClose} size={12} />
                <h2 className="text-xl font-semibold mb-4">Edit Model</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Provider</label>
                            <select
                                type="text"
                                name="provider"
                                value={formData.provider}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            >
                                <option value="" disabled>Select Provider</option>
                                {providers.map((provider) => (
                                    <option key={provider.value} value={provider.value}>
                                        {provider.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Temperature</label>
                            <input
                                type="number"
                                name="temperature"
                                value={formData.temperature}
                                onChange={handleChange}
                                step="0.1"
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Max Tokens</label>
                            <input
                                type="number"
                                name="max_tokens"
                                value={formData.max_tokens}
                                onChange={handleChange}
                                step="100"
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Budget Tokens</label>
                            <input
                                type="number"
                                name="budget_tokens"
                                value={formData.budget_tokens}
                                onChange={handleChange}
                                step="100"
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={handleClose}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isSaveDisabled && 'opacity-80 cursor-not-allowed'}`}
                            disabled={isSaveDisabled}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateModal;