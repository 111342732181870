import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaPen } from 'react-icons/fa';
import useNotificationStore from '../../store/notification-store';
import UpdateModal from './update-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

const columnHelper = createColumnHelper();

const Notification = () => {
  const { notification, fetchNotification, updateNotification } = useNotificationStore();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchNotification();
      setIsLoading(false);
    };

    fetchData();
  }, [fetchNotification]);

  const data = useMemo(() => [notification], [notification]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('text', {
        header: 'Text',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex space-x-4 items-center">
            <FaPen
              title="Edit"
              onClick={() => handleOpenEditModal(row.original)}
              className="cursor-pointer text-blue-500"
            />
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOpenEditModal = (notification) => {
    setSelectedNotification(notification);
    setIsUpdateModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsUpdateModalOpen(false);
    setSelectedNotification(null);
  };

  const handleUpdateNotification = async (notificationData) => {
    try {
      await updateNotification(notificationData);
      toast.success('Notification updated successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to update notification:', error);
      toast.error('Failed to update notification. Please try again.');
    }
  };

  return (
    <div className="max-w-screen-3xl mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Notification</h1>
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg mt-12">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className='grid grid-cols-12'>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700
                      ${header.column.id === 'text' ? 'col-span-10' : 'col-span-2'}
                      `}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
              ) :
                table.getRowModel().rows.length === 0 ? (
                  <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No notification found</p></td></tr>
                ) :
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50 grid grid-cols-12">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className={`py-3 px-4 border-b border-gray-200 text-sm text-gray-700 ${cell.column.id === 'text' ? 'col-span-10' : 'col-span-2'}`}>
                          <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      ))}
                    </tr>
                  ))}
          </tbody>
        </table>
      </div>
      <UpdateModal
        isOpen={isUpdateModalOpen}
        onRequestClose={handleCloseModal}
        notification={selectedNotification}
        onUpdate={handleUpdateNotification}
      />
    </div>
  );
};

export default Notification;
