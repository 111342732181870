import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { providers } from '../../utils/constants';

const CreateModal = ({ isOpen, onRequestClose, onCreate }) => {
    const initialFormData = {
        provider: '',
        name: '',
        temperature: 0,
        max_tokens: 0,
        budget_tokens: 0,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = async () => {
        setIsLoading(true);
        await onCreate(formData);
        setIsLoading(false);
        handleCancel();
    };

    const handleCancel = () => {
        setFormData(initialFormData);
        onRequestClose();
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'number' && value === '' ? 0 : value
        });
    };

    const isDisabled = !formData.provider || !formData.name || !formData.temperature;

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
                <h2 className="text-xl font-semibold mb-4">Create Model</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Provider</label>
                            <select
                                type="text"
                                name="provider"
                                value={formData.provider}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            >
                                <option value="" disabled>Select Provider</option>
                                {providers.map((provider) => (
                                    <option key={provider.value} value={provider.value}>
                                        {provider.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Temperature</label>
                            <input
                                type="number"
                                name="temperature"
                                value={formData.temperature}
                                onChange={handleChange}
                                step="0.1"
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Max Tokens</label>
                            <input
                                type="number"
                                name="max_tokens"
                                value={formData.max_tokens}
                                onChange={handleChange}
                                step="100"
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Budget Tokens</label>
                            <input
                                type="number"
                                name="budget_tokens"
                                value={formData.budget_tokens}
                                onChange={handleChange}
                                step="100"
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleCreate}
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-80 cursor-not-allowed'}`}
                            disabled={isDisabled}
                        >
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateModal;
