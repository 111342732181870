import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaPen, FaTrash } from 'react-icons/fa';
import useTransactionStore from '../../store/transactions-store';
import useUserStore from '../../store/user-store'
import useOrganisationStore from '../../store/organisations-store';
import UpdateModal from './update-modal';
import DeleteModal from '../../components/DeleteModal.jsx';
import Loader from '../../components/Loader';
import { formatDate } from '../../utils/helper_functions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const columnHelper = createColumnHelper();

const Transactions = () => {
    const { transactions, fetchTransactions, updateTransaction, deleteTransaction } = useTransactionStore();
    const { users, fetchUsers } = useUserStore();
    const { organisations, fetchOrganisations } = useOrganisationStore();

    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [filterStatus, setFilterStatus] = useState(null);

    const sortedUsers = useMemo(() => {
        return users.sort((a, b) => a.name.localeCompare(b.name));
    }, [users]);

    const sortedOrganisations = useMemo(() => {
        return organisations.sort((a, b) => a.name.localeCompare(b.name));
    }, [organisations]);

    const loadTransactions = useCallback(async () => {
        setIsLoading(true);
        await Promise.all([
            fetchUsers(null, selectedOrg, false),
            fetchTransactions(selectedUser, selectedOrg, filterStatus),
            fetchOrganisations()
        ]);
        setIsLoading(false);
    }, [fetchUsers, fetchTransactions, fetchOrganisations, selectedUser, selectedOrg, filterStatus]);

    useEffect(() => {
        loadTransactions();
    }, [loadTransactions]);

    const data = useMemo(() => {
        return transactions.map((transaction) => {
            const user = users.find((user) => user.id === transaction.user_id);
            const organisation = organisations.find(org => org.id === transaction.organisation_id);
            return {
            ...transaction,
            user_name: user?.name,
            organisation: organisation?.name,
            };
        });
    }, [transactions, users, organisations]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('user_name', {
                header: 'User Name',
            }),
            columnHelper.accessor('organisation', {
                header: 'Organisation',
                cell: info => info.getValue() ? info.getValue() : '-',
            }),
            columnHelper.accessor('status', {
                header: 'Status',
            }),
            columnHelper.accessor('amount', {
                header: 'Top-Up Amount',
            }),
            columnHelper.accessor('tokens', {
                header: 'Tokens',
                cell: info => info.getValue() ? info.getValue() : '0',
            }),
            columnHelper.accessor('type', {
                header: 'Type',
            }),
            columnHelper.accessor('created_at', {
                header: 'Created At',
                cell: (info) => formatDate(info.getValue()),
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaPen
                            title="Edit"
                            onClick={() => handleOpenUpdateModal(row.original)}
                            className="cursor-pointer text-blue-500"
                        />
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />
                    </div>
                ),
            }),
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const handleOpenUpdateModal = (transaction) => {
        setSelectedTransaction(transaction);
        setIsUpdateOpen(true);
    };

    const handleOpenDeleteModal = (transaction) => {
        setSelectedTransaction(transaction);
        setIsDeleteOpen(true);
    };

    const handleCloseModal = () => {
        setIsUpdateOpen(false);
        setIsDeleteOpen(false);
        setSelectedTransaction(null);
    };

    const handleupdateTransaction = async (id, transactionData) => {
        try {
            await updateTransaction(id, transactionData);
            toast.success('Transaction updated successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update transaction:', error);
            toast.error('Failed to update transaction. Please try again.');
        }
    };

    const handleDeleteTransaction = async (id) => {
        try {
            await deleteTransaction(id);
            toast.success('Transaction deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete transaction:', error);
            toast.error('Failed to delete transaction. Please try again.');
        }
    };

    const noTransactionsFound = table.getRowModel().rows.length === 0;

    return (
        <div className="max-w-screen-3xl mx-auto mt-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Transactions List</h1>
            </div>
            <div className="flex gap-4 mb-4">
                <select
                    name="org_id"
                    value={selectedOrg || ''}
                    onChange={(e) => { setSelectedOrg(e.target.value) }}
                    className={`px-4 border w-48 rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${!selectedOrg && 'text-gray-500'}`}
                >
                    <option value="" className='text-gray-500'>Select Organisation</option>
                    {sortedOrganisations.map((org) => (
                        <option key={org.id} value={org.id}>
                            {org.name}
                        </option>
                    ))}
                </select>
                <select
                    name="user_id"
                    value={selectedUser}
                    onChange={(e) => { setSelectedUser(e.target.value) }}
                    className={`px-10 py-2 border w-48 rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${!selectedUser && 'text-gray-500'}`}
                >
                    <option value="" className='text-gray-500'>Select User</option>
                    {sortedUsers.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.name}
                        </option>
                    ))}
                </select>
                <select
                    name="status"
                    value={filterStatus}
                    onChange={(e) => { setFilterStatus(e.target.value) }}
                    className={`px-10 py-2 border rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${!filterStatus && 'text-gray-500'}`}
                >
                    <option value="" className='text-gray-500'>Filter Status</option>
                    {['Pending','Approved'].map((status) => (
                        <option key={status} value={status.toLowerCase()}>
                            {status}
                        </option>
                    ))}
                </select>
            </div>
            <div className={`overflow-x-auto relative border border-gray-300 rounded-[3px] ${isLoading && 'h-[65vh]'} ${noTransactionsFound && 'h-[50vh]'}`}>
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className={`py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700
                                            ${header.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50 sm:min-w-36' : ''}
                                            ${header.column.id.length < 10 && header.column.id !== 'actions' ? 'min-w-36 sm:min-w-44' : ''}
                                            ${header.column.id.length >= 10 && header.column.id.length < 15 ? 'min-w-52 sm:min-w-52' : ''}
                                            ${header.column.id.length >= 15 ? 'min-w-64 sm:min-w-64' : ''}
                                        `}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            isLoading ? (
                                <tr><td colSpan={columns.length}><Loader className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2" /></td></tr>
                            ) :
                                noTransactionsFound ? (
                                    <tr><td colSpan={columns.length}><p className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">No transactions found</p></td></tr>
                                ) :
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id} className="hover:bg-gray-50">
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}
                                                    className={`py-3 px-4 border-b border-gray-200 text-sm text-gray-700 ${cell.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50' : ''}`}
                                                >
                                                    <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                    </tbody>
                </table>
            </div>
            <UpdateModal
                isOpen={isUpdateOpen}
                onClose={handleCloseModal}
                transaction={selectedTransaction}
                onUpdate={handleupdateTransaction}
            />
            <DeleteModal
                isOpen={isDeleteOpen}
                onClose={handleCloseModal}
                data={{ ...selectedTransaction, dataName: 'transaction' }}
                onDelete={handleDeleteTransaction}
            />
        </div>
    );
};

export default Transactions;
