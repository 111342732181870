import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useTransactionStore = create((set) => ({
    transaction: null,
    transactions: [],
    fetchTransactions: async (userId, orgId, status) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/transactions`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
                params: { userId, orgId, status },
            });
            set({ transactions: response.data.transactions });
        } catch (error) {
            console.error('Failed to fetch transactions:', error);
        }
    },
    updateTransaction: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/transactions/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                transactions: state.transactions.map((transaction) =>
                    transaction.id === id ? response.data.transaction : transaction
                ),
            }));
            return response.data.transaction;
        } catch (error) {
            console.error('Failed to update transaction:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteTransaction: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/transactions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                transactions: state.transactions.filter((transaction) => transaction.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete transaction:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useTransactionStore;
