import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Reports from './pages/Reports/Reports';
import useUserStore from './store/user-store';
import ForgotPassword from './components/forgot-password';
import Users from './pages/Users/Users';
import Tools from './pages/tools/Tools'
import ToolPrompts from './pages/tool-prompts/ToolPrompts'
import ToolFields from './pages/tool_fields/ToolFields'
import ToolInputs from './pages/tool-inputs/ToolInputs'
import ToolOutputs from './pages/tool-outputs/ToolOutputs'
import Organisations from './pages/organisations/Organisations';
import Plans from './pages/plans/Plans'
import Transactions from './pages/transactions/Transactions';
import Templates from './pages/templates/Templates';
import UserTemplates from './pages/user-templates/UserTemplates';
import ApiClients from './pages/api-clients/ApiClients';
import ApiRuns from './pages/api-runs/ApiRuns';
import useWebSocket from 'react-use-websocket';
import useReportStore from './store/report-store';
import { toast } from 'react-toastify';
import './App.css';
import Vocabulary from './pages/vocabulary/Vocabulary';
import Models from './pages/models/Models';
import Notification from './pages/notification/Notification';

const WS_URL = process.env.REACT_APP_WS_URL || "wss://4c1yn1fja7.execute-api.eu-central-1.amazonaws.com/staging/"

const App = () => {
  const { updateReport, setRetryLoading} = useReportStore();

  const user = useUserStore((state) => state.user);
  const userId = localStorage.getItem('user_id') || "";

  const location = useLocation();
  const showNavbar = location.pathname !== '/login' && location.pathname !== '/reset-password';

  useEffect(() => {
    window.Intercom("update");
  }, [location]);

  const { lastJsonMessage } = useWebSocket(
    `${WS_URL}?userId=${userId}`,
    {
      onOpen: () => console.log('WebSocket connection established.'),
      onClose: () => console.log('WebSocket connection closed.'),
      onMessage: (event) => console.log('Event: ', event),
      shouldReconnect: () => true,
      heartbeat: {
        message: 'ping',
        returnMessage: 'pong',
        timeout: 5 * 60000,
        interval: 5 * 1000,
      },
    }
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const payload = lastJsonMessage.data;
      console.log("lastJsonMessage: ", lastJsonMessage);

      if (payload.status === "generated") {
      updateReport(payload);
      setRetryLoading(payload.id, false);
      toast.success(`Report# ${payload.row_number}: Report generated successfully.`, { toastId: `toast-${payload.id}` });
      } 
      else if (payload.status === "processing") {
        setRetryLoading(payload.id, true);
        updateReport(payload);
      }
      else if (payload.status === "completed" && !payload.report_fetched) {
        if (payload.error === "no_transcription_found") {
          toast.error(`Report# ${payload.row_number}: Transcription failed. Please try a different recording.`, { toastId: `toast-${payload.id}` });
        } else {
          toast.error(`Report# ${payload.row_number}: The report could not be created. Please try again.`, { toastId: `toast-${payload.id}` });
        }
        updateReport(payload);
        setRetryLoading(payload.id, false);
      }
    }
  }, [userId, lastJsonMessage, updateReport, setRetryLoading]);

  return (
    <>
      {showNavbar && <Navbar />}
      <div className={`max-w-screen-3xl mx-auto ${showNavbar ? 'px-6': ''}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reports" element={user ? <Reports /> : <Navigate to="/login" />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/tool-prompts" element={<ToolPrompts />} />
          <Route path="/tool-fields" element={<ToolFields />} />
          <Route path="/tool-inputs" element={<ToolInputs />} />
          <Route path="/tool-outputs" element={<ToolOutputs />} />
          <Route path="/users" element={<Users />} />
          <Route path="/organisations" element={<Organisations />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/user-templates" element={<UserTemplates />} />
          <Route path="/vocabulary" element={<Vocabulary />} />
          <Route path="/models" element={<Models />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/api-clients" element={<ApiClients />} />
          <Route path="/api-runs" element={<ApiRuns />} />
          <Route path='/reset-password' element={<ForgotPassword />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
