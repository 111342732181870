import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
import useModelStore from '../../store/models-store';
import CreateModal from './create-modal';
import UpdateModal from './update-modal';
import DeleteModal from '../../components/DeleteModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

const columnHelper = createColumnHelper();

const Models = () => {
  const { models, fetchModels, createModel, updateModel, deleteModel } = useModelStore();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedModel, setSelectedModel] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchModels();
      setIsLoading(false);
    };

    fetchData();
  }, [fetchModels]);

  const data = useMemo(() => models.sort((a, b) => a.name.localeCompare(b.name)), [models]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('provider', {
        header: 'Provider',
      }),
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('temperature', {
        header: 'Temperature',
      }),
      columnHelper.accessor('max_tokens', {
        header: 'Max Tokens',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('budget_tokens', {
        header: 'Budget Tokens',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex space-x-4 items-center">
            <FaPen
              title="Edit"
              onClick={() => handleOpenEditModal(row.original)}
              className="cursor-pointer text-blue-500"
            />
            <FaTrash
              title="Delete"
              onClick={() => handleOpenDeleteModal(row.original)}
              className="cursor-pointer text-red-600"
            />
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: { globalFilter },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleOpenCreateModal = () => setIsCreateModalOpen(true);

  const handleOpenEditModal = (model) => {
    setSelectedModel(model);
    setIsUpdateModalOpen(true);
  };
  const handleOpenDeleteModal = (model) => {
    setSelectedModel(model);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setIsUpdateModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedModel(null);
  };

  const handleCreateModel = async (data) => {
    try {
      await createModel(data);
      toast.success('Model created successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to create model:', error);
      toast.error('Failed to create model. Please try again.');
    }
  };

  const handleUpdateModel = async (id, modelData) => {
    try {
      await updateModel(id, modelData);
      toast.success('Model updated successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to update model:', error);
      toast.error('Failed to update model. Please try again.');
    }
  };

  const handleDeleteModel = async (id) => {
    try {
      await deleteModel(id);
      toast.success('Model deleted successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to delete model:', error);
      toast.error('Failed to delete model. Please try again.');
    }
  };

  return (
    <div className="max-w-screen-3xl mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Model List</h1>
        <button
          className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={handleOpenCreateModal}
        >
          <FaPlus className="mr-2" /> Create New
        </button>
      </div>
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted()
                        ? header.column.getIsSortedDesc()
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
              ) :
                table.getRowModel().rows.length === 0 ? (
                  <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No models found</p></td></tr>
                ) :
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                          <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      ))}
                    </tr>
                  ))}
          </tbody>
        </table>
      </div>
      <CreateModal
        isOpen={isCreateModalOpen}
        onRequestClose={handleCloseModal}
        onCreate={handleCreateModel}
      />
      <UpdateModal
        isOpen={isUpdateModalOpen}
        onRequestClose={handleCloseModal}
        model={selectedModel}
        onUpdate={handleUpdateModel}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseModal}
        data={{ ...selectedModel, dataName: 'model' }}
        onDelete={handleDeleteModel}
      />
    </div>
  );
};

export default Models;
