import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useNotificationStore = create((set) => ({
  notification: {text: ''},
  fetchNotification: async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/notification`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set({ notification: response.data.notification });
    } catch (error) {
      console.error('Failed to fetch notification:', error);
    }
  },
  updateNotification: async (notificationData) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/notification`, notificationData, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set({ notification: response.data.notification });
      return response.data.notification;
    } catch (error) {
      console.error('Failed to update notification:', error);
      throw new Error(error.response.data.message);
    }
  }
}));

export default useNotificationStore;
