import React, { useEffect, useState } from 'react';
import { FaFileDownload, FaPen, FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { getSignedUrl } from '../utils/helper_functions';
import useOrganisationStore from '../store/organisations-store';
import useUserStore from '../store/user-store';
import { toast } from 'react-toastify';

const UploadTemplateDoc = ({ isOpen, onRequestClose, entity }) => {
  const [formData, setFormData] = useState({
    templateFile: null,
    fileName: '',
  });
  
  const [initialData, setInitialData] = useState({});

  const { updateOrganisation } = useOrganisationStore();
  const { editUser } = useUserStore();
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const updating = !!entity.docx_template_file_name;

  useEffect(() => {
    if (entity && (entity.docx_template_file_name !== initialData.docx_template_file_name)) {
      setInitialData(entity);
      setFormData((prev) => ({
        ...prev,
        templateFile: null, 
        fileName: entity.docx_template_file_name || '',
      }));
    }
  }, [entity, initialData.docx_template_file_name]);

  const isFormValid = updating
  ? (formData.templateFile !== null || formData.fileName?.trim() !== initialData?.docx_template_file_name?.trim())
  : (formData.templateFile !== null && formData.fileName?.trim() !== '');
  
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'templateFile' && files) {
      setFormData(prev => ({
        ...prev,
        templateFile: files[0],
      }));
    } else if (name === 'fileName') {
      setFormData(prev => ({
        ...prev,
        fileName: value,
      }));
    }
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const downloadUrl = await getSignedUrl({ 
        name: formData.fileName, 
        type: 'download',
        template: true,
      });
      
      if (downloadUrl) {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', formData.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setDownloading(false);
    } catch (error) {
      console.log('Download error: ', error);
      toast.error('Failed to download template document');
    }
  };

  const handleRemove = async () => {
    setRemoving(true);
    try {
      if (entity.type === 'users') {
        await editUser(entity.id, { docx_template_file_name: '' });
      } else {
        await updateOrganisation(entity.id, { docx_template_file_name: '' });
      }
      toast.success('Template document removed successfully');
    } catch (error) {
      console.log('Template removal error: ', error);
      toast.error('Failed to remove template document');
    } finally {
      setRemoving(false);
      onRequestClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const type = updating ? 'update' : 'upload';
      const template = {
        fileChanged: formData.templateFile !== null,
        fileRenamed: formData.fileName !== initialData.docx_template_file_name,
        oldName: initialData.docx_template_file_name,
      }
      const uploadUrl = await getSignedUrl({ name: formData.fileName, type, template });

      uploadUrl &&
        await fetch(uploadUrl, {
          method: 'PUT',
          body: formData.templateFile,
          headers: {
            'Content-Type': formData.templateFile.type,
          },
      });

      if (entity.type === 'users') {
        await editUser(entity.id, { docx_template_file_name: formData.fileName });
      } else {
        await updateOrganisation(entity.id, { docx_template_file_name: formData.fileName });
      }
      toast.success(`Template document ${updating ? 'updated' : 'added'} successfully`);
    } catch (error) {
      console.log('Template upload error: ', error);
      toast.error(`Failed to ${updating ? 'update' : 'add'} template document`);
    } finally {
      setLoading(false);
      onRequestClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onRequestClose}></div>
      <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
        <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
        <h2 className="text-xl font-semibold mb-4">{updating ? 'Update' : 'Add'} Template Document</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <label className="block text-gray-700 text-sm font-bold mb-2">Document</label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={formData.templateFile ? formData.templateFile.name : updating ? 'Uploaded' : 'No file chosen'}
                  className="px-4 py-2 border rounded-md shadow-sm bg-gray-100 text-gray-700 focus:outline-none flex-grow"
                  disabled
                />
                <label className="px-4 py-2 border rounded-md shadow-sm bg-blue-500 text-white cursor-pointer hover:bg-blue-600">
                  {updating ? <FaPen className='h-6'/> : <FaPlus className='h-6'/>}
                  <input
                    type="file"
                    name="templateFile"
                    onChange={handleChange}
                    className="hidden"
                  />
                </label>
                {updating && (
                  <>
                    <button
                      type="button"
                      onClick={handleDownload}
                      disabled={downloading}
                      className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-green-500 text-white hover:bg-green-600 ${
                        downloading ? 'opacity-70 cursor-not-allowed' : ''
                      }`}
                    >
                      <FaFileDownload className="h-6" />
                    </button>
                    <button
                      type="button"
                      onClick={handleRemove}
                      disabled={removing}
                      className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-red-500 text-white hover:bg-red-600 ${
                        removing ? 'opacity-70 cursor-not-allowed' : ''
                      }`}
                    >
                      <FaTrash className="h-6" />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <label className="block text-gray-700 text-sm font-bold mb-2">Document Name</label>
              <input
                type="text"
                name="fileName"
                placeholder='Enter custom name'
                value={formData.fileName}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder:font-light"
                required
              />
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${
                !isFormValid && 'opacity-80 cursor-not-allowed'
              }`}
              disabled={!isFormValid}
            >
              {loading ? (updating ? 'Updating..' : 'Adding..') : updating ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadTemplateDoc;
