import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useModelStore = create((set) => ({
  models: [],
  fetchModels: async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/models`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set({ models: response.data.models });
    } catch (error) {
      console.error('Failed to fetch models:', error);
    }
  },
  createModel: async (data) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/models`, data, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set((state) => ({ models: [...state.models, response.data.model] }));
      return response.data.model;
    } catch (error) {
      console.error('Failed to create model:', error);
      throw error;
    }
  },
  updateModel: async (id, updatedData) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/models/${id}`, updatedData, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set((state) => ({
        models: state.models.map((model) =>
          model.id === id ? response.data.model : model
        ),
      }));
      return response.data.model;
    } catch (error) {
      console.error('Failed to update model:', error);
      throw new Error(error.response.data.message);
    }
  },
  deleteModel: async (id) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/models/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set((state) => ({
        models: state.models.filter((model) => model.id !== id),
      }));
      return response.data.message;
    } catch (error) {
      console.error('Failed to delete model:', error);
      throw new Error(error.response.data.message);
    }
  },
}));

export default useModelStore;
